import * as React from 'react';
import { Container, Spinner } from 'reactstrap';
import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { UserLoginMenu } from './UserLoginMenu';
import SearchBar from './SearchBar';
import Footer from './Footer';
import ProfileMenu from './ProfileMenu';
import { CookiesUtil } from '../Module/CookiesUtil';
import { AccessToken } from './AccessToken';
import { Contact, OrderTotals, User } from '../store/interfaces';
import { SpinnerSize } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Notifications from './Notifications/Notifications';
import { fetchUrl, handleLogoutOnSessionExpire } from '../Module/Fetch';
import { PublicClientApplication } from '@azure/msal-browser';
import { authConfig } from '../authConfig';
import { Loader } from "@progress/kendo-react-indicators";
import { LANGUAGES } from '../store/constants';
import { stat } from 'fs';
import { total } from '@progress/kendo-react-upload/dist/npm/messages';
import i18n from '../i18n';
import { MoneyFormat } from '../Module/CurrencyUtil';

export function ChangeLanguage(props: { callBack: any }) {
    const { t, i18n } = useTranslation();
    const supportedLanguages = ["en", "fr"];
    var url = window.location.href;

    React.useEffect(() => {
        let userSelectedLangauge = CookiesUtil.getCookieValue("userLang");
        let contactCookie = CookiesUtil.getCookieValue("contact");
        let contact  = JSON.parse(contactCookie);
        var userLangCode : string | undefined = "en"; 
        if (contact.language_id != null) {
           let userLang = LANGUAGES.find(e => e.id == contact.language_id);
           userLangCode = userLang?.code;
        }

        if (userSelectedLangauge) {
            i18n.changeLanguage(userSelectedLangauge);
        } else {
            i18n.changeLanguage(userLangCode);
            CookiesUtil.setCookie("userLang", userLangCode, 1);
        }

        supportedLanguages.map(function (lng) {
            if (url.search("/" + lng + "/") > 0 || url.search("/" + lng) > 0) {
                CookiesUtil.setCookie("userLang", lng, 1);
                i18n.changeLanguage(lng);

            }
        });

        fetchUrl(`/Languages/ChangeLanguage?languageCode=${CookiesUtil.getCookieValue("userLang")}`, "GET")
        .then((response) => {
            handleLogoutOnSessionExpire(response);
            return response.json()
        })
        .then((json) => {
            props.callBack(json)
        });

    }, []);

    return <></>
}

export default class Layout extends React.PureComponent<{}, 
    { children?: React.ReactNode, accessToken: string | null,
        contact: Contact, language: any | null, user : User | null,
        orderTotals: OrderTotals | null
    }> {
    private contact: Contact = { first_name: "", last_name: "", email: "", language_id: null};

    private user: User = { id: 0, parent_id: 0, parent_type: 0, contact_id: 0, user_status_id: 0, created_date: new Date(), last_login_date: new Date(), email: "", parent_type_tag: "", parent_name: "" };

    private orderTotals = { total_so: 0, total_po: 0, nb_so: 0, nb_po: 0 };

    static contextType = MsalContext;

    public state = { accessToken: null, contact: this.contact, language: null, user: this.user, orderTotals: this.orderTotals };

    public onChangeLanguage = (lang: any) => {
        this.setState({ language: lang });
    }

    public tokenReceived = (token: string) => {
        this.setState({ accessToken: token });
        this.fetchContact()
        this.fetchUser();
    }

    private fetchContact() {
        fetchUrl("/contacts/get", "GET")
        .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
          })
          .then((contact : Contact) => {
                this.setState({ contact: contact })
                CookiesUtil.setCookie("contact", JSON.stringify(contact), 1);
          });
    }

    private async fetchUser() {
        fetchUrl("/users/get", "GET")
        .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<User>
          })
          .then((user : User) => {
                this.setState({ user: user })
                this.fetchOrderTotals();
                CookiesUtil.setCookie("user", JSON.stringify(user), 1);
          });
    }

    private fetchOrderTotals() {
        fetchUrl(`/orders/GetOrderTotals?dealerId=${this.state?.user?.parent_id}`, "GET")
        .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<OrderTotals>
          })
          .then((orderTotals : OrderTotals) => {
            this.setState({ orderTotals: orderTotals });
          });
    }

    public render() {
        return (
            <React.Fragment>
                <UnauthenticatedTemplate>
                    <UserLoginMenu />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AccessToken onTokenReceived={this.tokenReceived} />
                    {(
                        (this.state.accessToken != null && this.state.contact.first_name != "") &&
                        <>
                            <Notifications />
                            
                            <ChangeLanguage callBack={this.onChangeLanguage} />
                            {(
                                (this.state.language != null) &&
                                <>
                                    <ProfileMenu contact={this.state.contact} url={window.location.href} language={CookiesUtil.getCookieValue("userLang")} accessToken={this.state.accessToken} />
                                    <div className="container-fluid my-mt-4 gerry-content">
                                        {this.state.accessToken != "" && <SearchBar accessToken={this.state.accessToken} />}
                                        <div className="row px-md-4" id="root">
                                            <div className="col-md-12 ml-sm-auto col-lg-12 col-md-12 px-md-12">
                                                    {this.state.orderTotals.nb_po != 0 &&
                                                        <div className='order-summary-main'>
                                                            <div className='order-summary'>
                                                            
                                                            <div className='order-summary-totals'>
                                                                <div>{i18n.t("customerOrders")}</div>
                                                                <span>{this.state.orderTotals.nb_so}: {MoneyFormat(this.state.orderTotals.total_so.toString())}</span>
                                                            </div>

                                                            <div className='order-summary-totals'>
                                                                <div>{i18n.t("supplierOrders")}</div>
                                                                <span>{this.state.orderTotals.nb_po}: {MoneyFormat(this.state.orderTotals.total_po.toString())}</span>
                                                            </div>


                                                            </div>
                                                        </div>
}
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </>
                            )}
                        </>
                    )}
                    <div style={{ left: "50%", top: "50%", position: "absolute" }} hidden={this.state.accessToken != null && this.state.contact.first_name != "" && this.state.language != null}><Loader size="large" /> </div>
                </AuthenticatedTemplate>
            </React.Fragment>
        );
    }
}